import { useAuthenticatedSWR } from '../api';
import { SUBSCRIPTIONS_URL } from './constants';

export const useSubscriptions = () => {
  const { data, error } = useAuthenticatedSWR(SUBSCRIPTIONS_URL);

  return {
    isLoading: !error && !data?.data,
    isError: error,
    subscriptions: data?.data,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import { interpolate } from '@format/interpolate';
import { AddOnCard } from '../AddOnCard';
import { AddonCardsWrapper, CategoryHeading } from './styles';

export const AddonSections = ({
  addonSections,
  addonSelectionState,
  unavailableAddons,
  checkedAddons = [],
  notSelectableText = '',
  onChange,
  onDetailsClick,
}) => {
  const getAddOnCard = addon => {
    let notSelectable;

    if (checkedAddons.length) {
      const hasSku = checkedAddons.some(
        checkedAddon => checkedAddon.sku === addon.sku,
      );
      notSelectable = checkedAddons.length === 3 && !hasSku;
    }

    return (
      <AddOnCard
        {...addon}
        key={addon.sku}
        checked={Boolean(addonSelectionState[addon.sku]?.checked)}
        selectedValues={addonSelectionState[addon.sku] || {}}
        onChange={onChange}
        notSelectable={notSelectable}
        notSelectableText={notSelectableText}
        onDetailsClick={onDetailsClick}
        unavailable={unavailableAddons.includes(addon.sku)}
      />
    );
  };

  return (
    <>
      {addonSections.map((addonSection, i) => (
        <div key={i}>
          <CategoryHeading>
            {interpolate(addonSection.heading, {
              addonCount: addonSection.addons.length,
            })}
          </CategoryHeading>
          <AddonCardsWrapper>
            {addonSection.addons.map(addon => getAddOnCard(addon))}
          </AddonCardsWrapper>
        </div>
      ))}
    </>
  );
};
AddonSections.displayName = 'AddonSections';

AddonSections.propTypes = {
  addonSections: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      addons: PropTypes.array,
    }),
  ),
  addonSelectionState: PropTypes.object,
  onChange: PropTypes.func,
  notSelectableText: PropTypes.string,
  onDetailsClick: PropTypes.func,
  unavailableAddons: PropTypes.array,
  checkedAddons: PropTypes.array,
};

'use client';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import { ButtonLink } from '@web/atoms';
import { useReporter } from '../../reporter';
import {
  Container,
  Headline,
  Subheadline,
  ImageContainer,
  Message,
  PreMessage,
  Allergy,
  ButtonContainer,
  MessageContainer,
} from './styles.js';
import {
  noRecipesRemoveButtonShown,
  noRecipesRemoveClicked,
  planSelectionNoRecipesAllergiesEdited,
  planSelectionNoRecipesAvailable,
} from './events';

export const NoRecipesFound = ({
  headline,
  subheadline,
  buttonText,
  onClick,
  secondaryButtonText,
  onSecondaryButtonClick,
  onClickDeletePet,
  messagePreText,
  messagePostText,
  allergies,
  image,
}) => {
  const reporter = useReporter();

  useEffect(() => {
    reporter.tag(planSelectionNoRecipesAvailable());
  }, [reporter]);

  useEffect(() => {
    if (onClickDeletePet) {
      reporter.tag(noRecipesRemoveButtonShown());
    }
  }, [reporter, onClickDeletePet]);

  const onButtonClicked = () => {
    reporter.tag(planSelectionNoRecipesAllergiesEdited());
    onClick();
  };

  const onDeleteButtonClicked = () => {
    reporter.tag(noRecipesRemoveClicked());
    onClickDeletePet();
  };

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage
          src={image.src}
          alt={image.alt}
          width={240}
          height={240}
        />
      </ImageContainer>
      <MessageContainer>
        <Headline>{headline}</Headline>
        <Subheadline>{subheadline}</Subheadline>
        <Message>
          <PreMessage>{`${messagePreText} `}</PreMessage>
          {allergies.map((allergy, i) => {
            const isFirstAllergy = i === 0;
            const isLastAllergy = i === allergies.length - 1;
            return (
              <span key={allergy.label}>
                {!isFirstAllergy && <span>, {isLastAllergy && 'or '}</span>}
                <Allergy>{allergy.label}</Allergy>
              </span>
            );
          })}
        </Message>
        <Subheadline>{messagePostText}</Subheadline>
      </MessageContainer>
      <ButtonContainer top column>
        <Button onClick={onButtonClicked}>{buttonText}</Button>
        {secondaryButtonText?.length && onSecondaryButtonClick && (
          <Button onClick={onSecondaryButtonClick} variant="secondary">
            {secondaryButtonText}
          </Button>
        )}
      </ButtonContainer>
      {onClickDeletePet && (
        <ButtonContainer>
          <ButtonLink onClick={() => onDeleteButtonClicked()}>
            Remove this pup, and return to checkout
          </ButtonLink>
        </ButtonContainer>
      )}
    </Container>
  );
};
NoRecipesFound.displayName = 'NoRecipesFound';
NoRecipesFound.propTypes = {
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  secondaryButtonText: PropTypes.string,
  onSecondaryButtonClick: PropTypes.func,
  messagePreText: PropTypes.string.isRequired,
  messagePostText: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  allergies: PropTypes.array.isRequired,
  onClickDeletePet: PropTypes.func,
};

import useSWR from 'swr';
import { useExperiment, HIDE_VAN_LEEUWEN } from '../experiments';
import { CATEGORIES_URL } from './constants';
import { useCategories } from './useCategories';

export const useTreats = ({ petId, couponCode, allergies, weight } = {}) => {
  const { categories, isLoading: isCategoriesLoading } = useCategories();
  const category = categories?.find(cat => cat.name === 'Treats');
  const params =
    petId || couponCode || allergies?.length || weight
      ? new URLSearchParams({
          ...(petId && { pet_id: petId }),
          ...(couponCode && { coupon_code: couponCode }),
          ...(allergies && { allergies: allergies }),
          ...(weight && { weight: weight }),
        })
      : '';
  const { data, error } = useSWR(
    category?.id
      ? `${CATEGORIES_URL}${category?.id}/products/?${params.toString()}`
      : null,
  );

  const { value: hideVL } = useExperiment(HIDE_VAN_LEEUWEN, false);

  const formatDataAvailability = data =>
    data?.data.map(product => {
      const isAvailable = data.metadata.products_available.includes(
        product.sku,
      );
      const isRecommended = data.metadata.products_recommended.includes(
        product.sku,
      );
      return {
        ...product,
        product_available: isAvailable,
        product_recommended: isRecommended,
      };
    });

  const filteredFormattedData = formatDataAvailability(data)?.filter(addon =>
    hideVL ? addon.sku !== 'FR-ICECREAM-PBB' : addon,
  );

  return {
    isLoading: isCategoriesLoading || (!error && !data),
    isError: error,
    products: filteredFormattedData,
  };
};

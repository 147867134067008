import { interpolate } from '@format/interpolate';
import { truncatePrice } from '../products';
import {
  ADDON_PRODUCT_TYPES,
  ADDON_PRODUCT_TYPES_LIST,
  DENTAL_CHEWS_SKUS,
} from './constants';

export const makeSubAddon = subAddon => ({
  id: subAddon?.id,
  next_delivery_target: subAddon?.next_delivery_target,
  due_for_next_delivery: subAddon?.due_for_next_delivery,
  frequency: subAddon?.frequency,
  recurrence: subAddon?.recurrence,
  quantity: subAddon?.quantity,
  sku: subAddon?.sku,
});

export const makeAddonProduct = addonProduct => ({
  product_name: addonProduct?.data.product_name,
  product_type: addonProduct?.data.product_type,
  price: addonProduct?.data.price,
  discounted_price: addonProduct?.data.discounted_price,
  details: addonProduct?.data.details,
  category_ids: addonProduct?.data.category_ids,
});

export const findAddonProduct = (subAddon, addonProductList) =>
  addonProductList?.find(
    addonProduct => addonProduct.data.sku === subAddon.sku,
  );

export const findAndMakeAddonProduct = (subAddon, addonProductList) => {
  const addonProduct = findAddonProduct(subAddon, addonProductList);

  return makeAddonProduct(addonProduct);
};

export const makeSubAddonProduct = (subAddon, addonProductList) => ({
  ...makeSubAddon(subAddon),
  ...findAndMakeAddonProduct(subAddon, addonProductList),
});

export const formatData = (subAddons, addonProductList) =>
  subAddons?.map(subAddon => makeSubAddonProduct(subAddon, addonProductList));

export const getQuantityType = (type, sku) => {
  if (type === ADDON_PRODUCT_TYPES.treats) {
    if (sku?.includes('FR')) {
      return 'pint';
    } else {
      return 'bag';
    }
  } else if (type === ADDON_PRODUCT_TYPES.supplements) {
    return 'jar';
  } else {
    return 'item';
  }
};
export const addonsQuantityFormatter = (quantity, type, sku) => {
  const quantityType = getQuantityType(type, sku);

  return quantity > 1
    ? `${quantity} ${quantityType}s`
    : `${quantity} ${quantityType}`;
};

export const getMergedAddonModals = (addonModals, treats, supplements) =>
  addonModals.map(modal => {
    const product = [...supplements, ...treats].find(
      addon => addon.sku === modal.sku,
    );

    const saveText =
      parseFloat(product?.discounted_price) < parseFloat(product?.price)
        ? `(Save ${Math.floor(
            ((parseFloat(product.price) -
              parseFloat(product.discounted_price)) /
              parseFloat(product.price)) *
              100,
          )}%)` // math to get percent off full price
        : '';

    return interpolate(modal, {
      addonPrice: truncatePrice(product?.price),
      addonDiscount: truncatePrice(product?.discounted_price),
      addonSavePercentage: saveText,
    });
  });

export const getUnavailableAddons = (treats, supplements) => {
  if (treats?.length > 0 && supplements?.length > 0) {
    return [
      ...treats
        .filter(treat => !treat.product_available)
        .map(treat => treat.sku),
      ...supplements
        .filter(supplement => !supplement.product_available)
        .map(supplement => supplement.sku),
    ];
  } else {
    return [];
  }
};

export const getRecommendedDentalChew = treats =>
  treats?.find(
    addon =>
      DENTAL_CHEWS_SKUS.includes(addon.sku) && !!addon.product_recommended,
  );

export const getAvailabledAddons = (allAddonsinCMS, treats, supplements) => {
  const unavailableAddons = getUnavailableAddons(treats, supplements);
  const recommendedDentalChew = getRecommendedDentalChew(treats);
  return allAddonsinCMS
    .filter(item => ADDON_PRODUCT_TYPES_LIST.includes(item.productType))
    .filter(addon => !unavailableAddons?.includes(addon.sku))
    .filter(
      addon =>
        addon?.sku === recommendedDentalChew?.sku ||
        !DENTAL_CHEWS_SKUS.includes(addon?.sku),
    );
};

import useSWR from 'swr';
import { SUBSCRIPTIONS_URL } from './constants';

export const usePendingOrder = subscriptionId => {
  const { data, error, isValidating } = useSWR(
    subscriptionId
      ? `${SUBSCRIPTIONS_URL}${subscriptionId}/pending-order/`
      : null,
  );

  return {
    order: data,
    error,
    // Need to check `isValidating` because this endpoint returns no data
    // if there's no pending order.
    isLoading: isValidating && !data && !error,
  };
};

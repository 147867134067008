import { useSWRList } from '@hooks/use-swr-list';
import { CATEGORIES_URL } from './constants';
import { useCategories } from './useCategories';

export const useTreatList = (
  treats,
  { petId = '', couponCode = '', allergies = [] } = {},
) => {
  const { categories } = useCategories();

  const treatCategory = categories?.find(
    category => category.name === 'Treats',
  );

  const formattedParams =
    petId || couponCode || allergies?.length
      ? new URLSearchParams({
          ...(petId && { pet_id: petId }),
          ...(couponCode && { coupon_code: couponCode }),
          ...(allergies && { allergies: allergies.join(', ') }),
        })
      : '';

  const { data, error } = useSWRList(
    treats && treatCategory?.id
      ? treats?.map(
          treatSku =>
            `${CATEGORIES_URL}${treatCategory.id}/products/${treatSku}/${
              formattedParams && '?' + formattedParams.toString()
            }`,
        )
      : null,
  );

  const formatData = data =>
    data?.map(treatData => ({
      ...treatData.data,
      ...treatData.metadata,
    }));

  return {
    treats: formatData(data),
    isLoading: !error && !data,
    error,
  };
};

'use client';
import styled from 'styled-components';
import { generateContentIconStyle } from '@ui/icons';
import { Label } from '@web/atoms/Dropdown/styles';

export const Card = styled.div`
  border-radius: ${({ theme }) => theme.spacing(8)};
  background: ${props =>
    props.$unavailable
      ? props.theme.colors.background.disabled
      : props.theme.colors.background.lightest};
  display: inline-flex;
  flex-direction: column;
  user-select: none;
  position: relative;
  width: 350px;
  border: ${props =>
    props.checked ? `2px solid ${props.theme.colors.secondary.egg}` : 'none'};
  opacity: ${({ $notSelectable }) => ($notSelectable ? 0.5 : 1)};
`;

export const ImageContainer = styled.div`
  border-radius: ${({ theme }) => theme.spacing(4)};
  object-fit: fill;
  filter: ${props => props.$unavailable && 'grayscale(100%) brightness(93%)'};
  overflow: hidden;
  width: 40%;
  img {
    border-radius: 16px;
  }
`;

export const CardHeader = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(4, 4, 1)};
  min-height: 9.5rem;
`;

export const CardHeaderText = styled.div`
  margin: auto;
  margin-left: ${({ theme }) => theme.spacing(2)};
  width: 60%;
`;

export const Name = styled.h1`
  ${({ theme }) => theme.typography.heading6}
  ${({ theme }) => theme.fontFamily.base}
`;

export const SubTitle = styled.p`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const Size = styled.p`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const DisplayedPrice = styled.span`
  ${({ theme }) => theme.typography.body}
  margin-right: ${({ theme }) => theme.spacing(1)};
  font-weight: bold;
`;

export const SavePercentage = styled.span`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.status.errorPrimary};
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const StrikeThroughPrice = styled.span`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.status.errorPrimary};
  margin-right: ${({ theme }) => theme.spacing(1)};
  text-decoration-line: line-through;
  text-transform: capitalize;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(0, 4)};
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const MoreDetails = styled.button`
  position: relative;
  z-index: 1;
  border: none;
  padding: 0 3px;
  max-height: 30px;
  display: ${props => (props.$unavailable ? 'none' : 'block')};
  background-color: transparent;
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.hero.huckleberry};
  text-align: left;
  &:focus {
    border-radius: ${props => props.theme.spacing(2)};
    outline: 2px solid ${({ theme }) => theme.colors.neutral.darkestFur};
  }
`;

export const Description = styled.div`
  ${({ theme }) => theme.typography.body}
  margin: ${({ theme }) => theme.spacing(1)} 0;
  min-height: 80px;
`;

export const DropDownSection = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.text.tertiary};
  margin-top: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(0, 6)};
  border-bottom-left-radius: ${({ theme }) => theme.spacing(8)};
  border-bottom-right-radius: ${({ theme }) => theme.spacing(8)};
`;

export const DropdownWrapper = styled.div`
  width: 100%;
`;

export const DropDowns = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: ${({ theme }) => theme.spacing(6)} auto;

  ${Label} {
    ${({ theme }) => theme.typography.bodyBold};
    color: ${({ theme }) => theme.colors.text.lightest};
  }

  ${DropdownWrapper} {
    &:first-of-type {
      max-width: 120px;
      margin-right: 1rem;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing(4)};
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  padding: 2px;
  &:focus-within {
    border-radius: ${props => props.theme.spacing(2)};
    outline: 2px solid ${({ theme }) => theme.colors.neutral.darkestFur};
  }
`;

export const UnavailableContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spacing(4)};
  align-items: center;

  div {
    display: inline-block;
    text-align: center;
  }
`;

export const UnavailableText = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
  gap: ${props => props.theme.spacing(4)};
  ${({ theme }) => theme.typography.label}
  color: ${({ theme }) => theme.colors.text.secondary};
  &::before {
    ${props => props.disabled && generateContentIconStyle('close')};
    width: ${props => props.theme.spacing(4)};
    height: ${props => props.theme.spacing(4)};
    background: ${props => props.theme.colors.border.dark};
  }
  &::after {
    content: '';
    border-radius: ${props => props.theme.spacing(8)};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const BadgeContainer = styled.div`
  position: absolute;
  top: 6px;
  left: ${props => props.theme.spacing(1)};
  z-index: 1;
  border: 1px solid white;
  border-radius: ${props => props.theme.spacing(4)};
`;

import { BaseService } from '../api';
import { SUBSCRIPTIONS_URL } from './constants';

export class SubscriptionService extends BaseService {
  updateSubscription = async (id, subscription) =>
    await this.client.patch(`${SUBSCRIPTIONS_URL}${id}/`, subscription);

  skipNextDelivery = async id =>
    await this.client.patch(
      `${SUBSCRIPTIONS_URL}${id}/skip_next_delivery/`,
      {},
    );

  reactivatePlan = async (id, couponCode, campaignId) =>
    await this.client.put(
      `${SUBSCRIPTIONS_URL}${id}/activate/${
        campaignId ? `?campaign_id=${campaignId}` : ''
      }`,
      couponCode
        ? {
            coupon_code: couponCode,
          }
        : {},
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ui/button';
import { Icon } from '@ui/icons';
import { interpolate } from '@format/interpolate';
import { ResponsiveImage } from '@web/molecules';
import { Tooltip, PillText } from '@web/atoms';
import {
  EXTRA_FREQUENCY,
  DENTAL_CHEWS_SIZE_MAP,
  NOT_SELECTABLE_TEXT,
} from './constants';
import {
  Card,
  ImageContainer,
  CardInfo,
  CardHeader,
  MoreDetails,
  Description,
  DisplayedPrice,
  StrikeThroughPrice,
  CardHeaderText,
  Name,
  SubTitle,
  Size,
  SavePercentage,
  ButtonContainer,
  ButtonWrapper,
  UnavailableText,
  UnavailableContainer,
  BadgeContainer,
} from './styles.js';

export const AddOnCard = ({
  title,
  subtitle,
  description,
  checked = false,
  selectedValues,
  size,
  image,
  onChange,
  onDetailsClick,
  pricingInfo = null,
  productType,
  unavailable,
  unavailableText,
  unavailableLabel,
  notSelectable,
  notSelectableText = NOT_SELECTABLE_TEXT,
  sku,
  selectionLabel = 'Add to your plan',
  dropdowns,
  badge,
  badgeDropdown,
  unit,
  level,
}) => {
  let labelText = selectionLabel;
  if (unavailable) {
    switch (productType) {
      case 'TR':
        labelText = 'Treat Unavailable';
        break;
      case 'SP':
        labelText = 'Supplement Unavailable';
        break;
      default:
        labelText = unavailableLabel;
        break;
    }
  } else if (checked) {
    labelText = 'Added';
  }
  const { price, discountedPrice, saveText } = pricingInfo;

  const quantityField = dropdowns?.find(item => item.name === 'quantity');
  //function to get the current unit
  quantityField?.options.map(
    (option, index) =>
      (quantityField.options[index].label = interpolate(option.label, {
        unit: unit,
      })),
  );

  const getAddonCard = props => (
    <Card
      {...props}
      $unavailable={unavailable}
      checked={checked}
      $notSelectable={notSelectable}
    >
      <CardHeader>
        {badge && (
          <BadgeContainer>
            <PillText text={badge} variant={badgeDropdown} />
          </BadgeContainer>
        )}
        <ImageContainer $unavailable={unavailable}>
          <ResponsiveImage
            src={image.src}
            alt={image.alt}
            height={image.height}
            width={image.width}
          />
        </ImageContainer>
        <CardHeaderText>
          <Name as={level}>{title}</Name>
          <SubTitle>{subtitle}</SubTitle>
          <Size>{size}</Size>
          {pricingInfo && !unavailable && (
            <div>
              {parseFloat(discountedPrice) < parseFloat(price) // don't show discount information if there is no savings
                ? Boolean(price) && (
                    <>
                      <StrikeThroughPrice>${price}</StrikeThroughPrice>
                      <DisplayedPrice>${discountedPrice}</DisplayedPrice>
                      {Boolean(saveText) && (
                        <SavePercentage>{saveText}</SavePercentage>
                      )}
                    </>
                  )
                : Boolean(price) && <DisplayedPrice>${price}</DisplayedPrice>}
            </div>
          )}
        </CardHeaderText>
      </CardHeader>
      <CardInfo>
        <Description>{unavailable ? unavailableText : description}</Description>
        {!unavailable && (
          <ButtonContainer>
            <MoreDetails
              type="button"
              $unavailable={unavailable}
              disabled={notSelectable}
              onClick={() => {
                onDetailsClick({ value: sku });
              }}
            >
              {!checked && <>View Details</>}
              {checked && (
                <>
                  {DENTAL_CHEWS_SIZE_MAP[selectedValues.sku]
                    ? `${DENTAL_CHEWS_SIZE_MAP[selectedValues.sku]}, `
                    : ''}
                  {
                    quantityField?.options.find(
                      item => item.value === selectedValues.quantity,
                    ).label
                  }
                  {', '}
                  {selectedValues.recurrence === null
                    ? EXTRA_FREQUENCY[selectedValues.frequency]
                    : 'Next box only'}
                </>
              )}
            </MoreDetails>
            <ButtonWrapper>
              <Button
                variant={checked ? 'egg' : 'primary'}
                onClick={e => {
                  e.preventDefault();
                  onDetailsClick({ value: sku });
                }}
                verticalPadding={3}
                disabled={unavailable || notSelectable}
              >
                {checked && (
                  <>
                    <Icon
                      iconName="check"
                      size={1}
                      id="icon_button_addon"
                      color="text.primary"
                      alt="Added"
                    />
                    {labelText}
                  </>
                )}
                {!checked && (
                  <>
                    <Icon
                      iconName="plus"
                      size={1}
                      id="icon_button_addon"
                      color="text.lightest"
                      alt="Add"
                    />
                    {labelText}
                  </>
                )}
              </Button>
            </ButtonWrapper>
          </ButtonContainer>
        )}
        {unavailable && (
          <>
            <UnavailableContainer>
              <UnavailableText disabled={unavailable}>
                {labelText}
              </UnavailableText>
            </UnavailableContainer>
          </>
        )}
      </CardInfo>
    </Card>
  );

  return notSelectable && !unavailable ? (
    <Tooltip trigger={getAddonCard} placement="top" offsetY={-245}>
      {notSelectableText}
    </Tooltip>
  ) : (
    getAddonCard()
  );
};

AddOnCard.displayName = 'AddOnCard';
AddOnCard.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  unavailable: PropTypes.bool,
  checked: PropTypes.bool,
  unavailableText: PropTypes.string,
  unavailableLabel: PropTypes.string,
  notSelectable: PropTypes.bool,
  notSelectableText: PropTypes.string,
  description: PropTypes.node.isRequired,
  productType: PropTypes.string,
  selectedValues: PropTypes.shape({
    sku: PropTypes.string,
    quantity: PropTypes.number,
    frequency: PropTypes.number,
  }),
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
  onDetailsClick: PropTypes.func,
  sku: PropTypes.string,
  selectionLabel: PropTypes.string,
  dropdowns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      noSelectionLabel: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
  pricingInfo: PropTypes.shape({
    price: PropTypes.string,
    discountedPrice: PropTypes.string,
    saveText: PropTypes.string,
  }),
  badge: PropTypes.string,
  badgeDropdown: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

import { useSWRConfig } from 'swr';
import { ApiResult, useService, wrapErrorWithResult } from '../api';
import { useSubscriptions } from './useSubscriptions';
import { useDeliveryDates } from './useDeliveryDates';
import { useNextDeliveryTarget } from './useNextDeliveryTarget';
import { SubscriptionService } from './service';
import { SUBSCRIPTIONS_URL } from './constants';

//TODO: This hook will be refined on #WEB-1603
export const useSubscription = petId => {
  const service = useService(SubscriptionService);
  const { mutate } = useSWRConfig();
  const { subscriptions, isLoading: isSubscriptionLoading } =
    useSubscriptions();
  const subscription = subscriptions?.find(sub => sub.pet_id === petId);
  const { updateDeliveryDates } = useDeliveryDates(subscription?.id);
  const { nextDeliveryDate, isNextDeliveryTargetLoading } =
    useNextDeliveryTarget(subscription);

  //TODO: This mutation should be fixed when implementing this hook. Currently this
  //mutate is using SUBSCRIPTIONS_URL as key
  const mutateSubscription = data => {
    return mutate(
      SUBSCRIPTIONS_URL,
      {
        data: subscriptions.map(sub =>
          sub.id === subscription.id ? data : sub,
        ),
      },
      false,
    );
  };

  const updateSubscription = async data => {
    try {
      const response = await service.updateSubscription(subscription.id, data);
      await updateDeliveryDates();
      await mutateSubscription(response.data);
      return ApiResult.success(response);
    } catch (error) {
      return wrapErrorWithResult(error);
    }
  };

  const skipNextDelivery = async () => {
    try {
      const response = await service.skipNextDelivery(subscription.id);
      mutateSubscription(response.data);
      return ApiResult.success(response);
    } catch (error) {
      return wrapErrorWithResult(error);
    }
  };
  const reactivatePlan = async (couponCode, campaignId) => {
    try {
      const response = await service.reactivatePlan(
        subscription.id,
        couponCode,
        campaignId,
      );
      mutateSubscription(response.data);
      return ApiResult.success(response);
    } catch (error) {
      return wrapErrorWithResult(error);
    }
  };
  return {
    subscription,
    isLoading: isSubscriptionLoading || isNextDeliveryTargetLoading,
    nextDeliveryDate,
    updateSubscription,
    skipNextDelivery,
    reactivatePlan,
  };
};

'use client';
import styled from 'styled-components';

export const CategoryHeading = styled.h2`
  text-align: center;
  max-width: 688px;
  margin: ${props => props.theme.spacing(2)} auto 0;
  color: ${props => props.theme.colors.text.primary};
  padding: ${props => props.theme.spacing(0, 0, 4)};
  ${props => props.theme.typography.heading2};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(2, 0, 2, 0)};
    ${props => props.theme.typography.heading4};
  }
`;

export const AddonCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: min-content;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 0 1rem;
  margin: auto;
  max-width: fit-content;

  @media ${props => props.theme.tablet} {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(450px, max-content)); */
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding-top: ${props => props.theme.spacing(2)};
    padding-bottom: ${props => props.theme.spacing(4)};
    ${props => props.theme.typography.heading4};
    &:last-child {
      /* border is needed to replace margin due to how box-model collapsing margins */
      border-right: ${props => props.theme.spacing(5)} solid transparent;
      padding-bottom: ${props => props.theme.spacing(4)};
    }
  }

  @media ${props => props.theme.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

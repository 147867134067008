import React from 'react';
import PropTypes from 'prop-types';
import {
  BaseHeader,
  LeftMenuLinks,
  LoggedInMenuLink,
  CenterMenuLinks,
} from '@web/molecules';

export const AccountHeader = ({ links, logoUrl, logoSrText, name }) => {
  const rightNavSegment = <LoggedInMenuLink name={name} />;
  const leftNavSegment = <LeftMenuLinks links={links} />;
  const centerNavSegment = <CenterMenuLinks links={links} />;
  return (
    <BaseHeader
      logoUrl={logoUrl}
      logoSrText={logoSrText}
      rightSegment={rightNavSegment}
      centerSegment={centerNavSegment}
      leftSegment={leftNavSegment}
      variant="white"
    />
  );
};

LoggedInMenuLink.displayName = 'LoggedInMenuLink';
LoggedInMenuLink.propTypes = {
  name: PropTypes.string.isRequired,
};

AccountHeader.displayName = 'AccountHeader';
AccountHeader.propTypes = {
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

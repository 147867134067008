import { ApiResult, useAuthenticatedSWR, useService } from '../api';
import { SUBSCRIPTIONS_URL } from './constants';
import { AddonService } from './service';

export const useSubscriptionAddons = subscriptionId => {
  const addonService = useService(AddonService);
  const {
    data: addons,
    mutate,
    error,
    isValidating,
  } = useAuthenticatedSWR(
    subscriptionId ? `${SUBSCRIPTIONS_URL}${subscriptionId}/addons/` : null,
  );

  const createMutateSubscriptionAddons = data => {
    mutate([...addons, data], true);
  };

  const createAddon = async data =>
    ApiResult.callAsync(async () => {
      const response = await addonService.createAddon(subscriptionId, data);
      createMutateSubscriptionAddons(response.data);
      return response;
    });

  const updateSubscriptionAddon = data => {
    mutate(
      addons.map(addon => (addon.id === data.id ? data : addon)),
      true,
    );
  };

  const deleteSubscriptionAddon = deletedAddonId => {
    const filteredAddons =
      addons?.filter(addon => addon.id !== deletedAddonId) ?? [];
    mutate([...filteredAddons], true);
  };

  const updateAddon = async (addonId, data) =>
    ApiResult.callAsync(async () => {
      const response = await addonService.updateAddon(
        subscriptionId,
        addonId,
        data,
      );
      updateSubscriptionAddon(response.data);
      return response;
    });

  const deleteAddon = async addonId =>
    ApiResult.callAsync(async () => {
      const response = await addonService.deleteAddon(subscriptionId, addonId);
      deleteSubscriptionAddon(addonId);
      return response;
    });
  return {
    isLoading: subscriptionId ? !error && !addons : false,
    isError: error,
    addons,
    createAddon,
    updateAddon,
    deleteAddon,
    isValidating,
  };
};

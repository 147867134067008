export const planSelectionNoRecipesAvailable = () => ({
  type: 'No Recipes Available Screen Loaded',
});

export const planSelectionNoRecipesAllergiesEdited = () => ({
  type: 'No Recipes Available Screen Allergies Edited',
});

export const noRecipesRemoveClicked = () => ({
  type: 'Extra dog with no recipes removed',
});

export const noRecipesRemoveButtonShown = () => ({
  type: 'Extra dog with no recipes available',
});

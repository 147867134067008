import { eachDayOfInterval, format, parseISO, startOfDay } from 'date-fns';
import useSWR from 'swr';
import { useMemo } from 'react';
import { SUBSCRIPTIONS_URL } from './constants';

const getMinDate = deliveryDates => deliveryDates?.[0];

const getMaxDate = deliveryDates => deliveryDates?.[deliveryDates.length - 1];

export const getDisabledDates = (deliveryDates, minDate, maxDate) =>
  deliveryDates && minDate && maxDate
    ? eachDayOfInterval({
        start: minDate,
        end: maxDate,
      })
        .filter(item => !deliveryDates.includes(format(item, 'yyyy-MM-dd')))
        .map(item => startOfDay(item))
    : [];

export const useDeliveryDates = subscriptionId => {
  const { data, error, mutate } = useSWR(
    subscriptionId
      ? `${SUBSCRIPTIONS_URL}${subscriptionId}/delivery_dates/`
      : null,
  );
  const minDate = getMinDate(data?.next_date);
  const maxDate = getMaxDate(data?.next_date);
  const minDateValue = useMemo(
    () => (minDate ? parseISO(minDate) : null),
    [minDate],
  );
  const maxDateValue = useMemo(
    () => (maxDate ? parseISO(maxDate) : null),
    [maxDate],
  );
  const disabledDates = useMemo(
    () => getDisabledDates(data?.next_date, minDateValue, maxDateValue),
    [data?.next_date, maxDateValue, minDateValue],
  );

  return {
    isLoading: !error && !data,
    isError: error,
    deliveryDates: data?.next_date,
    disabledDates,
    minDate: minDateValue,
    maxDate: maxDateValue,
    updateDeliveryDates: mutate,
  };
};

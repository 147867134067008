import useSWR from 'swr';
import { CATEGORIES_URL } from './constants';
import { useCategories } from './useCategories';

export const useSupplements = ({ petId, couponCode, allergies } = {}) => {
  const { categories, isLoading: isCategoriesLoading } = useCategories();
  const category = categories?.find(cat => cat.name === 'Supplements');
  const params =
    petId || couponCode || allergies?.length
      ? new URLSearchParams({
          ...(petId && { pet_id: petId }),
          ...(couponCode && { coupon_code: couponCode }),
          ...(allergies?.length && { allergies: allergies }),
        })
      : '';

  const { data, error } = useSWR(
    category?.id
      ? `${CATEGORIES_URL}${category?.id}/products/?${params.toString()}`
      : null,
  );

  const formatDataAvailabilty = data =>
    data?.data.map(product => {
      const isAvailable = !!data.metadata.products_available.find(
        availableSku => availableSku === product.sku,
      );
      return {
        ...product,
        product_available: isAvailable,
      };
    });

  return {
    isLoading: isCategoriesLoading || (!error && !data),
    isError: error,
    products: formatDataAvailabilty(data),
  };
};

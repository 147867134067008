import React from 'react';
import PropTypes from 'prop-types';
import { ProductCard, ProductCardCRO, CollapsibleMenu } from '@web/molecules';
import { toggleItem } from '../../toggle';
import {
  PRICE_PARITY_STARTING_AT_TEXT_KEY,
  SHOW_LAMB,
  useExperiment,
} from '../../experiments';
import {
  ProductSelectionContainer,
  ProductCardWrapper,
  MenuWrapper,
} from './styles.js';

export const ProductSelector = ({
  products,
  collapsedProducts = [],
  unavailableProducts = [],
  selected = [],
  onChange,
  onDetailsClick,
  variant = 'checkbox',
  period = 'week',
  productCardVariant = 'classic',
  direction = 'horizontal',
  notSelectableText,
  buttonIcon,
}) => {
  const handleChange = (selected, product) => {
    variant === 'checkbox'
      ? onChange?.(toggleItem(selected, product))
      : !selected.includes(product) && onChange?.([product]);
  };

  const { value: showPriceText } = useExperiment(
    PRICE_PARITY_STARTING_AT_TEXT_KEY,
    false,
  );

  const { value: showLambUnavailableText } = useExperiment(SHOW_LAMB, true);

  const lambShortageText =
    "Our Lamb Dish with Cranberries is temporarily out of stock due to an inventory shortage. We're working like dogs to get this recipe back on the menu and appreciate your patience and understanding.";

  return (
    <ProductSelectionContainer
      $gridColumns={products.length === 4 ? 2 : 3}
      $direction={direction}
    >
      {products.map(product => {
        const isUnavailable = unavailableProducts.includes(product.value);
        const notSelectable =
          selected.length === 4 && !selected.includes(product.value);
        return (
          <ProductCardWrapper key={product.value}>
            {productCardVariant === 'classic' ? (
              <ProductCard
                {...product}
                unavailable={isUnavailable}
                unavailableText={
                  product.value !== 'lamb'
                    ? product.unavailableText
                    : showLambUnavailableText
                    ? product.unavailableText
                    : lambShortageText
                }
                notSelectable={notSelectable}
                notSelectableText={notSelectableText}
                onDetailsClick={() => onDetailsClick(product)}
                onChange={e => handleChange(selected, product.value)}
                checked={selected.includes(product.value)}
                inputType={variant}
                callout={product.callout}
                period={period}
                showPriceText={showPriceText}
                buttonIcon={buttonIcon}
              />
            ) : (
              <ProductCardCRO
                {...product}
                unavailable={isUnavailable}
                unavailableText={
                  product.value !== 'lamb'
                    ? product.unavailableText
                    : showLambUnavailableText
                    ? product.unavailableText
                    : lambShortageText
                }
                notSelectable={notSelectable}
                notSelectableText={notSelectableText}
                onDetailsClick={() => onDetailsClick(product)}
                onChange={e => handleChange(selected, product.value)}
                checked={selected.includes(product.value)}
                inputType={variant}
                callout={product.callout}
                period={period}
                showPriceText={showPriceText}
                buttonIcon={buttonIcon}
              />
            )}
          </ProductCardWrapper>
        );
      })}
      {collapsedProducts.length > 0 && (
        <MenuWrapper>
          <CollapsibleMenu menuText="See more plans">
            {collapsedProducts.map(product => {
              const isUnavailable = unavailableProducts.includes(product.value);
              return (
                <ProductCardWrapper key={product.value}>
                  {variant === 'classic' ? (
                    <ProductCard
                      direction="row"
                      {...product}
                      unavailable={isUnavailable}
                      unavailableText={product.unavailableText}
                      onDetailsClick={() => onDetailsClick(product)}
                      onChange={e => handleChange(selected, product.value)}
                      checked={selected.includes(product.value)}
                      inputType={variant}
                      callout={product.callout}
                      notSelectableText={notSelectableText}
                      showPriceText={showPriceText}
                      showPriceSymbol={!isUnavailable}
                      buttonIcon={buttonIcon}
                    />
                  ) : (
                    <ProductCardCRO
                      direction="row"
                      {...product}
                      unavailable={isUnavailable}
                      unavailableText={product.unavailableText}
                      onDetailsClick={() => onDetailsClick(product)}
                      onChange={e => handleChange(selected, product.value)}
                      checked={selected.includes(product.value)}
                      inputType={variant}
                      callout={product.callout}
                      notSelectableText={notSelectableText}
                      showPriceText={showPriceText}
                      showPriceSymbol={!isUnavailable}
                      buttonIcon={buttonIcon}
                    />
                  )}
                </ProductCardWrapper>
              );
            })}
          </CollapsibleMenu>
        </MenuWrapper>
      )}
    </ProductSelectionContainer>
  );
};

ProductSelector.displayName = 'ProductSelector';
ProductSelector.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  collapsedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  unavailableProducts: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onDetailsClick: PropTypes.func,
  variant: PropTypes.oneOf(['checkbox', 'radio']),
  productCardVariant: PropTypes.oneOf(['classic', 'cro']),
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  notSelectableText: PropTypes.string.isRequired,
  period: PropTypes.string,
  buttonIcon: PropTypes.string,
};

'use client';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: ${props => props.theme.spacing(6, 0)};
  max-width: 960px;
  margin: auto;
  text-align: center;
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(12, 0)};
  }
`;

export const Headline = styled.h1`
  ${props => props.theme.typography.heading1};
  margin-bottom: ${props => props.theme.spacing(8)};
  margin-top: ${props => props.theme.spacing(3)};
  @media ${props => props.theme.tablet} {
    ${props => props.theme.typography.heading1};
  }
`;

export const Subheadline = styled.h6`
  ${props => props.theme.typography.heading6};
  font-weight: normal;
`;

export const ImageContainer = styled.div`
  margin: 0;
  max-width: 210px;
  @media ${props => props.theme.tablet} {
    max-width: 336px;
  }
  margin-left: auto;
  margin-right: auto;
`;

export const Message = styled.p`
  ${props => props.theme.typography.body};
  margin: ${props => props.theme.spacing(8)} 0;
  @media ${props => props.theme.tablet} {
    ${props => props.theme.typography.body};
  }
`;

export const PreMessage = styled.span`
  display: block;
  font-weight: bold;
`;

export const Allergy = styled.span`
  text-transform: capitalize;
  font-weight: bold;
`;

export const ButtonContainer = styled.div`
  gap: ${props => props.theme.spacing(5)};
  margin-top: ${props => props.theme.spacing(6)};
  min-width: 300px;
  ${({ top }) =>
    top &&
    css`
      margin-top: ${props => props.theme.spacing(10)};
    `}
  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
      display: inline-flex;
    `}
  @media ${props => props.theme.tablet} {
    min-width: 400px;
  }
`;

export const MessageContainer = styled.div`
  padding: 0 ${props => props.theme.spacing(2)};
  width: 100%;
`;

import { parseISO } from 'date-fns';
import { useOrder } from '../orders';

export const useNextDeliveryTarget = subscription => {
  let nextDeliveryDate = null;

  const { order, isLoading } = useOrder(subscription?.current_order_id);

  if (!isLoading && subscription?.is_locked_in) {
    nextDeliveryDate = parseISO(order?.delivery_date);
  } else if (subscription) {
    nextDeliveryDate = parseISO(subscription?.next_delivery_target);
  }

  return {
    isNextDeliveryTargetLoading: !nextDeliveryDate,
    nextDeliveryDate,
  };
};

import { useSWRList } from '@hooks/use-swr-list';
import { CATEGORIES_URL } from './constants';
import { formatData } from './functions';
import { useSubscriptionAddons } from './useSubscriptionAddons';

export const useSubscriptionAddonProducts = (
  subscriptionId,
  { petId = '', couponCode = '', allergies = [] } = {},
  showAddons,
) => {
  const {
    addons: subAddons,
    createAddon,
    updateAddon,
    deleteAddon,
    isLoading: subscriptionAddonsLoading,
    isValidating,
  } = useSubscriptionAddons(subscriptionId);

  const formattedParams =
    petId || couponCode || allergies?.length
      ? new URLSearchParams({
          ...(petId && { pet_id: petId }),
          ...(couponCode && { coupon_code: couponCode }),
          ...(allergies && { allergies: allergies.join(', ') }),
        })
      : '';
  const { data: addonProductList, error } = useSWRList(
    showAddons && !subscriptionAddonsLoading && subAddons?.length !== 0
      ? subAddons?.map(
          addon =>
            `${CATEGORIES_URL}${addon.category_ids[0]}/products/${addon.sku}/${
              formattedParams && '?' + formattedParams.toString()
            }`,
        )
      : null,
  );

  // TODO(James) this block shouldn't be necessary – can we remove?
  if (subAddons?.length === 0 || !showAddons)
    return {
      data: [],
      isLoading: false,
      createAddon,
      updateAddon,
      deleteAddon,
      error: false,
    };

  const isLoading = subscriptionAddonsLoading || (!error && !addonProductList);
  return {
    data: formatData(subAddons, addonProductList),
    isLoading,
    createAddon,
    updateAddon,
    deleteAddon,
    error,
    isValidating,
  };
};

import JerkyTreatChickenAppleImage from '../../images/our-food/addons/JerkyTreats-ChickenApple.jpg';

export const quantityDropdownTreatProps = {
  name: 'quantity',
  label: 'Quantity',
  noSelectionLabel: 'Select',
  options: [
    { label: '1 Bag', value: 1 },
    { label: '2 Bags', value: 2 },
    { label: '3 Bags', value: 3 },
    { label: '4 Bags', value: 4 },
    { label: '5 Bags', value: 5 },
    { label: '6 Bags', value: 6 },
  ],
};
export const frequencyDropdownProps = {
  name: 'frequency',
  label: 'Frequency',
  noSelectionLabel: 'Select',
  options: [
    { label: 'Every box', value: 1 },
    { label: 'Every other box', value: 2 },
  ],
};

export const quantityDropdownSupplementProps = {
  name: 'quantity',
  label: 'Quantity',
  noSelectionLabel: 'Select',
  options: [
    { label: '1 Jar', value: 1 },
    { label: '2 Jars', value: 2 },
    { label: '3 Jars', value: 3 },
    { label: '4 Jars', value: 4 },
    { label: '5 Jars', value: 5 },
    { label: '6 Jars', value: 6 },
  ],
};

export const addOnTypes = {
  treats: 'TR',
  supplements: 'SP',
};

export const addonCardProps = {
  label: 'Add to your plan',
  unavailableText:
    'Based on the allergies Kona has, this extra is unavailable due to allergy concerns. Please contact our Canine Care team if you have any questions.',
};

export const addonCards = [
  {
    ...addonCardProps,
    value: 'Chicken and Apple Jerky',
    sku: 'JERKYSTRIPS-CH',
    productName: 'Chicken and Apple Jerky',
    title: 'Chicken and Apple Recipe Jerky',
    description: 'Simple and delicious, your dog will crave these treats.',
    productType: 'TR',
    subtitle: 'Soft Chew Treats',
    size: '1 bag (5oz.)',
    pricingInfo: { price: '37', discountedPrice: '25' },
    image: {
      src: JerkyTreatChickenAppleImage.src,
      alt: 'Chicken And Apple Recipe Jerky Image',
      width: JerkyTreatChickenAppleImage.width,
      height: JerkyTreatChickenAppleImage.height,
    },
  },
];

export const EXTRA_FREQUENCY = {
  1: 'every box',
  2: 'every other box',
};

export const DENTAL_CHEWS_SIZE_MAP = {
  'SUPP-DENT-XS': 'Extra Small',
  'SUPP-DENT-SM': 'Small',
  'SUPP-DENT-MD': 'Medium',
  'SUPP-DENT-LG': 'Large',
};

export const NOT_SELECTABLE_TEXT =
  'To choose this item, please remove one of your previous selections.';

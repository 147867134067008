import uniqBy from 'lodash/uniqBy';
import flattenDeep from 'lodash/flattenDeep';
import { useSWRList } from '@hooks/use-swr-list';
import { useSubscriptions } from '../subscriptions';
import { CATEGORIES_URL } from './constants';
import { formatData } from './functions';

export const useSubscriptionAddonProductsList = () => {
  const { subscriptions, isLoading: isSubscriptionsLoading } =
    useSubscriptions();
  const allAddons = flattenDeep(
    subscriptions?.map(subscription => subscription.addons) ?? [],
  );
  const uniqueAddons = uniqBy(allAddons, addon => addon.sku);
  const { data: addonProductList, error } = useSWRList(
    uniqueAddons.map(addon => {
      return `${CATEGORIES_URL}${addon.category_ids[0]}/products/${addon.sku}/`;
    }),
  );
  // returning and object of subscription id keys and addons with prices as values
  const isLoading =
    isSubscriptionsLoading ||
    (uniqueAddons.length > 0 && !error && !addonProductList);
  const addonsBySubId = !isLoading
    ? subscriptions.reduce(
        (acum, sub) => ({
          ...acum,
          [sub.id]: formatData(sub.addons, addonProductList),
        }),
        {},
      )
    : undefined;
  return {
    data: addonsBySubId,
    isLoading,
    error,
  };
};

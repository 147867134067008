export const SUBSCRIPTIONS_URL = '/api/v3/subscriptions/';
export const CATEGORIES_URL = '/api/v3/categories/';

export const ADDON_PRODUCT_TYPES = {
  treats: 'TR',
  supplements: 'SP',
};
export const ADDON_PRODUCT_TYPES_LIST = Object.values(ADDON_PRODUCT_TYPES);

export const DENTAL_CHEWS_SKUS = [
  'SUPP-DENT-XS',
  'SUPP-DENT-SM',
  'SUPP-DENT-MD',
  'SUPP-DENT-LG',
];

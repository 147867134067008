import { BaseService } from '../api';
import { SUBSCRIPTIONS_URL } from './constants';

export class AddonService extends BaseService {
  createAddon = async (id, addon) =>
    await this.client.post(`${SUBSCRIPTIONS_URL}${id}/addons/`, addon);

  updateAddon = async (subscriptionId, addonId, payload) => {
    const res = await this.client.patch(
      `${SUBSCRIPTIONS_URL}${subscriptionId}/addons/${addonId}/`,
      payload,
    );
    return res;
  };

  deleteAddon = async (subscriptionId, addonId) => {
    const res = await this.client.delete(
      `${SUBSCRIPTIONS_URL}${subscriptionId}/addons/${addonId}/`,
    );
    return res;
  };
}

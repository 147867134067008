import { useSWRList } from '@hooks/use-swr-list';
import { CATEGORIES_URL } from './constants';
import { useCategories } from './useCategories';

export const useSupplementList = (
  supplements,
  { petId = '', couponCode = '', allergies = [] } = {},
) => {
  const { categories } = useCategories();

  const supplementCategory = categories?.find(
    category => category.name === 'Supplements',
  );

  const formattedParams =
    petId || couponCode || allergies?.length
      ? new URLSearchParams({
          ...(petId && { pet_id: petId }),
          ...(couponCode && { coupon_code: couponCode }),
          ...(allergies && { allergies: allergies.join(', ') }),
        })
      : '';

  const { data, error } = useSWRList(
    supplements && supplementCategory?.id
      ? supplements?.map(
          supplementSku =>
            `${CATEGORIES_URL}${
              supplementCategory?.id
            }/products/${supplementSku}/${
              formattedParams && '?' + formattedParams.toString()
            }`,
        )
      : null,
  );

  const formatData = data =>
    data?.map(supplementData => ({
      ...supplementData.data,
      ...supplementData.metadata,
    }));

  return {
    supplements: formatData(data),
    isLoading: !error && !data,
    error,
  };
};
